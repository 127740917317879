@media (min-width: 800px) {
    .calc-machine-lay{
        /* Frame 427320671 */
    
    /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 9px;
    
        position: absolute;
        width: 514px;
        height: 604px;
        right: 20px;
        bottom: 20px;
        option{
            font-weight: 500;
        }
    
    }
    .calc-machine-way-wrap{
        width: 454px;
        height: 72px;
        background-color: white;
        border-radius: 10px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .calc-machine-way-item{
        display: flex;
        flex-direction: row;
        height: 24px;
        span{
            margin-left: 10px;
            width: 430px;
            border: none;
            color: black;
            outline: none;
        }
        hr{
            margin-left: 22px;
            border: 0;
            height: 1px; 
            background: #E4E6E9;
            background-image: -webkit-linear-gradient(left, #fff, #000, #fff);
            background-image: -moz-linear-gradient(left, #fff, #000, #fff);
            background-image: -ms-linear-gradient(left, #fff, #000, #fff);
            background-image: -o-linear-gradient(left, #fff, #000, #fff);  
    
                
            width: 430px;
        }
    }
    .calc-change-btn{
        box-sizing: border-box;
    
        position: absolute;
        width: 40px;
        height: 40px;
        left: 396px;
        top: 45px;
    
        /* белый */
        background: #FFFFFF;
        /* Серый-4 */
        border: 1px solid #C9CED2;
        border-radius: 100px;
    }
    .calc-machine{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;
        gap: 24px;
        width: 454px;
        height: 423px;
        background: #FFFFFF;
        border-radius: 14px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-deliviry-type{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 454px;
        height: 36px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-deliviry-type-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px 6px 12px;
        gap: 8px;
        width: 69px;
        height: 24px;
        background: #ffffff;
        border-radius: 50px;
        color: #626C75;
        border: 1px solid #626C75;
        flex: none;
        order: 0;
        flex-grow: 0;
    
    }
    
    
    .calc-deliviry-type-item-act{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px 6px 12px;
        gap: 8px;
        width: 69px;
        height: 24px;
        background: #1F2225;
        border-radius: 50px;
        flex: none;
        order: 0;
        flex-grow: 0;
    
    }
    
    .calc-parapms-layout{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 454px;
        height: 154px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-params-wrap{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 454px;
        height: 73px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-param-wrap{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        width: 223px;
        height: 73px;
        background: #FFFFFF;
        border: 1px solid #C9CED2;
        border-radius: 16px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-param-values-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
        gap: 4px;
        width: 141px;
        height: 43px;
        flex: none;
        order: 0;
        flex-grow: 1;
    
    }
    
    .calc-param-values-wrap-names{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 4px;
        width: 141px;
        height: 17px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-params-btns{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 12px 0px 0px;
        margin-left: auto;
        margin: auto;
        margin-left: auto;
        margin-right: auto;
        gap: 12px;
        align-items: center;
        width: 18px;
        height: 61px;
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    
    .calc-parap-select{
        /* Frame 427320487 */
    
        box-sizing: border-box;
    
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 20px;
        gap: 10px;
    
        width: 454px;
        height: 73px;
    
        /* Серый-4 */
        border: 1px solid #C9CED2;
        border-radius: 16px;
    
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        justify-content: center;
        select{

            width: 424px;
            height: 58px;
            border: none;
            outline: none;
    
        }
    }
    
    .calc-results{
        /* Frame 427320611 */
        /* Frame 427320669 */
    
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 24px;
    
        width: 454px;
        height: 165px;
    
    
        /* Inside auto layout */
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-result-text-wrap{
        /* Frame 427320611 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 126px;
    
        width: 454px;
        height: 77px;
    
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-deliviry-result{
        /* Frame 427320609 */
    
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
    
        margin: 0 auto;
        width: 143px;
        height: 77px;
    
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    
    .calc-deliviry-result-title{
        /* Heading */
    
        width: 143px;
        height: 17px;
    
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 17px */
    
        /* темный-4 */
        color: #626C75;
    
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
    }
    
    .calc-deliviry-result-value{
        /* Frame 427320424 */
    
        width: 143px;
        height: 48px;
        display: flex;
        flex-direction: row;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        justify-content: space-between;
        text-wrap: nowrap;
        div{
            /* Button */
    
            width: 105px;
            height: 48px;
    
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 110%;
            text-wrap: nowrap;
            /* identical to box height, or 48px */
    
            /* темный-1 */
            color: #1F2225;
    
    
        }
        span{
            /* Button */
    
    
            width: 30px;
            height: 24px;
    
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            margin-top: 19px;
            color: #626C75;
    
    
        }
    }
    
    .calc-next-action-btn{
        /* Frame 201 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;
        gap: 7px;
    
        width: 404px;
        height: 34px;
    
        /* красный-1 */
        background: #C3372E;
        border-radius: 65px;
    
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    
    }

    .calc-param-values-wrap-names-text{
        font-size: 150%;
        color: black;
        border: none;
        outline: none;
    }
}



@media (max-width: 800px) {
    .calc-machine-lay{
        padding: 0px;
        gap: 9px;
        width: calc(100%);
        aspect-ratio: 514/604;
        border-radius: 15px;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        option{
            font-weight: 500;
        }
    
    }
    .calc-machine-way-wrap{
        width: calc(100% - 40px);
        aspect-ratio: 345/70;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .calc-machine-way-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            margin-left: 7px;
            width: 430px;
            border: none;
            color: black;
            outline: none;
            background-color: #FFFFFF;
        }
        hr{
            margin-left: 22px;
            border: 0;
            height: 1px; 
            background: #E4E6E9;
            background-image: -webkit-linear-gradient(left, #fff, #000, #fff);
            background-image: -moz-linear-gradient(left, #fff, #000, #fff);
            background-image: -ms-linear-gradient(left, #fff, #000, #fff);
            background-image: -o-linear-gradient(left, #fff, #000, #fff);  
    
                
            width: 430px;
        }
    }
    .calc-change-btn{
        box-sizing: border-box;
        position: absolute;
        width: 10%;
        height: 1;
        aspect-ratio: 1;
        FONT-WEIGHT: 500;
        top: 35%;
        left: 75%;
        background: #FFFFFF;
        border: 1px solid #C9CED2;
        border-radius: 100px;
    }
    .calc-machine{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        gap: 16px;
        width: calc(100% - 40px);
        aspect-ratio: 345/493;
        background: #FFFFFF;
        border-radius: 14px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-deliviry-type{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        /* justify-content: space-between; */
        padding: 0px;
        gap: 8px;
        width: 100%;
        height: 24px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-deliviry-type-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 11px 11px 8px;
        gap: 5px;
        width: 18%;
        height: 15px;
        background: #ffffff;
        border-radius: 50px;
        color: #626C75;
        border: 1px solid #626C75;
        flex: none;
        order: 0;
        flex-grow: 0;
        img{
            width: 40%;
        }
        font-size: 80%;
    }
    
    
    .calc-deliviry-type-item-act{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 11px 11px 8px;
        gap: 5px;
        width: 18%;
        height: 15px;
        background: #000000;
        border-radius: 50px;
        color: #ffffff;
        border: 1px solid #1F2225;
        flex: none;
        order: 0;
        flex-grow: 0;
        img{
            width: 25px;
            height: 25px;
        }
        font-size: 80%;
    }
    
    .calc-parapms-layout{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0px;
        margin-top: 3%;
        gap: 10%;
        width: 100%;
        aspect-ratio: 313 / 208;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-params-wrap{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        flex-direction: column;
        gap: 8px;
        justify-content: space-between;
        width: 100%;
        aspect-ratio: 313 / 136;
        /* height: 48px; */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-param-wrap{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
        width: 100%;
        /* height: 48px; */
        aspect-ratio: 313 / 64;
        background: #FFFFFF;
        border: 1px solid #C9CED2;
        border-radius: 16px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-param-values-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        gap: 2.6px;
        width: 80%;
        height: calc(100% - 20px);
        
    
    }
    
    .calc-param-values-wrap-names{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 4px;
        width: 100%;
        height: 50%;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        font-size: 90%;
        border: none;
    }

    .calc-param-values-wrap-names-text{
        font-size: 150%;
        color: black;
        border: none;
        outline: none;
    }

    .calc-divider-line{
        height: 100%;
    }
    
    .calc-params-btns{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px 0px 0px;
        margin-left: auto;
        margin: auto;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        width: 10%;
        height: calc(100% - 20px);
    
        img{
            width: 20px;
            height: 20px;
        }
    }
    
    .calc-parap-select{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 13px;
        gap: 6px;
        width: 100%;
        aspect-ratio: 313 / 64;
        border: 1px solid #C9CED2;
        border-radius: 16px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        justify-content: center;
        select{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: white;
        }
    }
    
    .calc-results{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 15px;
        width: 100%;
        height: 108px;
        flex: none;
        margin-top: 10%;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-result-text-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 83px;
        width: 100%;
        aspect-ratio: 313/59;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        align-items: baseline;
    }
    
    .calc-deliviry-result{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        margin: 0 auto;
        width: 40%;
        height: 5px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    
    .calc-deliviry-result-title{
               /* width: 94px; */
        /* height: 11px; */
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 100%;
        line-height: 120%;
        color: #626C75;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .calc-deliviry-result-value{
        /* Frame 427320424 */
    
        width: 94px;
        height: 31px;
        display: flex;
        flex-direction: row;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        justify-content: space-between;
        text-wrap: nowrap;
        div{
            width: 100%;
            height: 31px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 150%;
            line-height: 120%;
            color: #1F2225;
        }
        span{
            /* Button */
    
    
            width: 20px;
            height: 15px;
    
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 120%;
            margin-top: 12px;
            color: #626C75;
    
    
        }
    }
    
    .calc-next-action-btn{
        /* Frame 201 */
    
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        gap: 7px;
    
        width: calc(100% - 40px);
    
        /* красный-1 */
        background: #C3372E;
        border-radius: 42px;
    
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    
    }
}

.modal-wrap{
    width: calc(86% - 20px);
    aspect-ratio: 392/282;
    position: absolute;
    right: 0;
    top: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    z-index: 3;
    min-width: 340px;
    padding: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 10px 25px 12px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 10px 25px 12px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 10px 25px 12px rgba(34, 60, 80, 0.2);
}

.modal-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span{
        width: 80%;
        color: black;
        font-size: 150%;
        font-weight: 500;
    }
    img{
       width: 25px;
       height: 25px;
    }
}

.modal-filter {
    width: 100%; 
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px;
    white-space: nowrap; 
  }
  
  .calc-filt-item {
    padding: 1% 3%;
    color: #000;
    border-radius: 100px;
    border: 1px solid #000000;
    margin-right: 5px;
    text-wrap: nowrap;
    overflow: hidden;
  }
  
  .calc-cities-wrap {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    aspect-ratio: 392 / 164;
    scrollbar-width:thin;          /* "auto" или "thin"  */
    scrollbar-color: #C3372E #E4E6E9;   /* плашка скролла и дорожка */ 
  }

  .calc-cities-wrap::-webkit-scrollbar-button {
    display: none;
  }

  .calc-cities-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px; /* Adjust the radius as needed */
  }

  .calc-cities-wrap::-webkit-scrollbar-track {
    border-radius: 10px; /* Adjust the radius as needed */
  }
  
  .city-item {
    flex: 1; /* Make each item take up equal width */
    margin-right: 10px; 
    color: #000;
  }
  
  input[type="radio"] {
    display: none; 
  }
  
  .city-label {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    font-size: 120%;
    font-weight: 500;
    cursor: pointer;
  }
  
  .city-marker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #E4E6E9; /* Default gray color */
    margin-right: 5px;
  }
  
  input[type="radio"]:checked + label .city-marker {
    width: 20px;
    height: 20px;
    background-color: #C3372E; /* Checked state - gray color */ 
  } 
  
.calc-filt-item-act{
    padding: 1% 3%;
    color: #fafafa;
    border-radius: 100px;
    background: black;
    border: 1px solid #000000;
    margin-right: 5px;
    text-wrap: nowrap;
    overflow: hidden;
}