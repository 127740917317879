@media (min-width: 800px) {
    .blog-wrap{
        margin-top: 5%;
        width: 97%;
        display: flex;
        flex-direction: column;
    }
    
    .blog-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .blog-title{
        font-size: 4vh;
            font-weight: 500;
            width: 90%;
            text-align: left;
            color: #1F2225;
            line-height: 4vh;
    }
    
    .blog-header-btn-wrap{
        border: 1px solid black;
        text-wrap: nowrap;
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        height: fit-content;
        span{
            font-size: 120%;
            font-weight: 300;
            line-height: 100%;
        }
    }
    
    .blog-slider-wrap{
        margin-top: 2%;
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        scroll-snap-type: x mandatory; 
        width: 100%; 
    }
    
    .blog-slider-item-wrap{
        width: 350px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item{
        width: 300px;
        padding: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        aspect-ratio: 572/507;
        border-radius: 18px;
        position: relative;
        margin-right: 20px;
        img{
            background-color: #C3372E;
            padding: 10px;
            width: 4%;
            aspect-ratio: 1;
            border-radius: 100px;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    
    .blog-slider-item-text-wrap{
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item-task{
        background-color: black;
        color: white;
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
    }
    
    .blog-slider-item-time{
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
        margin-top: 5px;
        width: fit-content;
    }
    
    .blog-slider-item-title{
        font-weight: 500;
        width: 95%;
    }
}

@media ((min-width: 530px) and (max-width: 800px)) {
    .blog-wrap{
        margin-top: 5%;
        width: 97%;
        display: flex;
        flex-direction: column;
    }
    
    .blog-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .blog-title{
        font-size: 2vh;
            font-weight: 500;
            width: 90%;
            text-align: left;
            color: #1F2225;
            line-height: 4vh;
    }
    
    .blog-header-btn-wrap{
        border: 1px solid black;
        text-wrap: nowrap;
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        height: fit-content;
        span{
            font-size: 100%;
            font-weight: 300;
            line-height: 100%;
        }
    }
    
    .blog-slider-wrap{
        margin-top: 2%;
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        scroll-snap-type: x mandatory; 
        width: 100%; 
    }
    
    .blog-slider-item-wrap{
        width: 350px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item{
        width: 300px;
        padding: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        aspect-ratio: 572/507;
        border-radius: 18px;
        position: relative;
        margin-right: 20px;
        img{
            background-color: #C3372E;
            padding: 10px;
            width: 4%;
            aspect-ratio: 1;
            border-radius: 100px;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    
    .blog-slider-item-text-wrap{
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item-task{
        background-color: black;
        color: white;
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
    }
    
    .blog-slider-item-time{
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
        margin-top: 5px;
        width: fit-content;
    }
    
    .blog-slider-item-title{
        font-weight: 500;
    }
}

@media ((min-width: 130px) and (max-width: 530px)) {
    .blog-wrap{
        margin-top: 5%;
        width: 97%;
        display: flex;
        flex-direction: column;
    }
    
    .blog-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .blog-title{
        font-size: 1.5vh;
            font-weight: 500;
            width: 80%;
            text-align: left;
            color: #1F2225;
            line-height: 2vh;
    }
    
    .blog-header-btn-wrap{
        border: 1px solid black;
        text-wrap: nowrap;
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        height: fit-content;
        span{
            font-size: 90%;
            font-weight: 300;
            line-height: 100%;
        }
    }
    
    .blog-slider-wrap{
        margin-top: 2%;
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        scroll-snap-type: x mandatory; 
        width: 100%; 
    }
    
    .blog-slider-item-wrap{
        width: 350px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item{
        width: 300px;
        padding: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        aspect-ratio: 572/507;
        border-radius: 18px;
        position: relative;
        margin-right: 20px;
        img{
            background-color: #C3372E;
            padding: 10px;
            width: 4%;
            aspect-ratio: 1;
            border-radius: 100px;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    
    .blog-slider-item-text-wrap{
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .blog-slider-item-task{
        background-color: black;
        color: white;
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
    }
    
    .blog-slider-item-time{
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border-radius: 100px;
        padding: 3px 10px;
        text-wrap: nowrap;
        font-size: 70%;
        margin-top: 5px;
        width: fit-content;
    }
    
    .blog-slider-item-title{
        font-weight: 500;
    }
}