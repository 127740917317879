@media (min-width: 1100px) {
    .calc-lay{
        /* 01 */
        display: flex;
        flex-direction: column;
        width: calc(98% - 20px);
        padding: 20px;
        height: fit-content;
        min-height: 700px;
        background: #E8E8E8;
        border-radius: 20px;
        margin-top: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; /* Центрирует изображение */
        background-attachment: fixed; /* Фиксирует изображение при прокрутке */
        background-position: center center;
        background-position-x: left;
        position: relative;
            color: white;
    }
    .calc-lay-title{
        margin-top: 5%;
        color: white;
        font-size: 250%;
    }

    .calc-btns-wrap{
        position: absolute;
        bottom: 20px;
    }
    
    #services{
        background-color: white;
        border-radius: 100px;
        border: 1px solid;
        padding: 5px 15px;
        height: fit-content;
        font-size: 130%;
        color: black;
        margin-right: 20px
    }
    
    #consultation{
        color: white;
        border-radius: 100px;
        border: 1px solid white;
        padding: 5px 15px;
        height: fit-content;
        font-size: 130%;
    }
    
    .calc-lay-desc{
        color: white;
        margin-top: 30px;
        width: 30%;
    }
    
    .calc-panel{
        width: 25%;
        min-width: 370px;
        min-height: 395px;
        max-height: 500px;
        height: 65%;
        background-color: white;
        border-radius: 15px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .faq h2{
        font-size: 190%;
        
    }
    .calc-panel label {
        margin-top: 10px;
    }
    .calc-panel span{
        font-size: 70%;
        text-align: center;
        margin-bottom: 5%;
    
    }
    .calc-panel form{
        width: 90%;
    }
    .calc-panel h2,
    .calc-panel span{
        width: fit-content;
        color: black;
    }
    .calc-panel input,
    .calc-panel textarea {
        padding: 5px;
        margin-top: 5px;
        border: none;
    }
    
    .calc-panel button {
        margin-top: 10px;
        padding: 15px;
        background-color: #C3372E;
        color: white;
        border: none;
        display: flex;
        border-radius: 100px;
        justify-content: space-between;
        width: 100%;
    }
}


@media ((min-width: 850px) and (max-width: 1100px)) {
    .calc-lay{
        /* 01 */
        display: flex;
        flex-direction: column;
        width: calc(98% - 20px);
        padding: 20px;
        height: fit-content;
        min-height: 700px;
        background: #E8E8E8;
        border-radius: 20px;
        margin-top: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; /* Центрирует изображение */
        background-attachment: fixed; /* Фиксирует изображение при прокрутке */
        background-position: center center;
        background-position-x: left;
        position: relative;
            color: white;
    }
    .calc-lay-title{
        margin-top: 8%;
        color: white;
        width: 40%;
        font-size: 200%;
    }

    .calc-btns-wrap{
        position: absolute;
        bottom: 20px;
    }
    
    #services{
        background-color: white;
        border-radius: 100px;
        border: 1px solid;
        padding: 5px 15px;
        height: fit-content;
        font-size: 130%;
        color: black;
        margin-right: 20px
    }
    
    #consultation{
        color: white;
        border-radius: 100px;
        border: 1px solid white;
        padding: 5px 15px;
        height: fit-content;
        font-size: 130%;
    }
    
    .calc-lay-desc{
        color: white;
        margin-top: 30px;
        width: 30%;
    }
    
    .calc-panel{
        width: 25%;
        min-width: 370px;
        min-height: 395px;
        max-height: 500px;
        height: 65%;
        background-color: white;
        border-radius: 15px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .faq h2{
        font-size: 190%;
        
    }
    .calc-panel label {
        margin-top: 10px;
    }
    .calc-panel span{
        font-size: 70%;
        text-align: center;
        margin-bottom: 5%;
    
    }
    .calc-panel form{
        width: 90%;
    }
    .calc-panel h2,
    .calc-panel span{
        width: fit-content;
        color: black;
    }
    .calc-panel input,
    .calc-panel textarea {
        padding: 5px;
        margin-top: 5px;
        border: none;
    }
    
    .calc-panel button {
        margin-top: 10px;
        padding: 15px;
        background-color: #C3372E;
        color: white;
        border: none;
        display: flex;
        border-radius: 100px;
        justify-content: space-between;
        width: 100%;
    }
}



@media ((min-width: 250px) and (max-width: 850px)) {
    .calc-lay{
        display: flex;
        flex-direction: column;
        width: calc(97% - 20px);
        padding: 10px;
        height: fit-content;
        min-height: 0;
        background: #E8E8E8;
        border-radius: 20px;
        margin-top: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-position: center center;
        background-position-x: left;
        position: relative;
        color: white;
    }
    .calc-lay-title{
        margin-top: 0%;
        color: white;
        width: 100%;
        font-size: 200%;
    }

    .calc-btns-wrap{
        margin-top: 20px;
    }
    
    #services{
        background-color: white;
        border-radius: 100px;
        border: 1px solid;
        padding: 2% 5%;
        height: fit-content;
        font-size: 130%;
        color: black;
        margin-right: 20px
    }
    
    #consultation{
        color: white;
        border-radius: 100px;
        border: 1px solid white;
        padding: 2% 5%;
        height: fit-content;
        font-size: 130%;
    }
    
    .calc-lay-desc{
        color: white;
        margin-top: 30px;
        width: 90%;
        display: none;
    }
    
    .calc-panel{
        width: 25%;
        min-width: 370px;
        min-height: 395px;
        max-height: 500px;
        height: 65%;
        background-color: white;
        border-radius: 15px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .faq h2{
        font-size: 190%;
        
    }
    .calc-panel label {
        margin-top: 10px;
    }
    .calc-panel span{
        font-size: 70%;
        text-align: center;
        margin-bottom: 5%;
    
    }
    .calc-panel form{
        width: 90%;
    }
    .calc-panel h2,
    .calc-panel span{
        width: fit-content;
        color: black;
    }
    .calc-panel input,
    .calc-panel textarea {
        padding: 5px;
        margin-top: 5px;
        border: none;
    }
    
    .calc-panel button {
        margin-top: 10px;
        padding: 15px;
        background-color: #C3372E;
        color: white;
        border: none;
        display: flex;
        border-radius: 100px;
        justify-content: space-between;
        width: 100%;
    }
}


.popup-layout{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    background: rgb(0 0 0 / 34%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-wrap{
    width: 40%;
    height: fit-content;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 350px;

    input{
        border: none;
        outline:none;
        padding: 5px;
        font-size: 20px;
        line-height: 22px;
    }

    form{
        width: calc(100% - 40px);
        margin-top: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
    }
}

.popup-title{
    font-size: 35px;
    font-weight: 500;
    width: fit-content;
}

.popup-desc{
    font-size: 16px;
    text-align: center;
    width: fit-content;
    margin-top: 15px;
}

.popup-input-wrap{
    width: calc(100% - 20px);
    padding: 10px;
    height: 53px;
    border: 1px solid #C9CED2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popup-input-title{
    font-size: 14px;
    color: #626C75;
}

.popap-action-btn{
    display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        gap: 7px;
    
        width: calc(100%);
        margin-top: 50px;
        background: #C3372E;
        border-radius: 42px;
        font-size: 20px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        border: none;
        color: white;
}