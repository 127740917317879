@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
}
input, textarea{
  width: 100%;
  outline: none;
}

*{
  text-decoration: none;
}


.App {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  overflow-x: hidden;
}

.calculator {
  border: 2px solid black;
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  select {
    width: 70%;
  }
}

.main-title-type-1{
  font-size: 4vh;
  font-weight: 500;
  width: 90%;
  text-align: left;
  color: #1F2225;
}

#logistics_types {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

#weight_types_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  input, select {
    width: 40%;
  }
}

#boxes_amount_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  input, p {
    width: 40%;
  }
}

.unit-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 2px solid black;

  .unit-option {
    width: fit-content;
    padding: 0 29px;
    text-align: center;
  }
  .unit-option.selected {
    border-bottom: 2px solid red;
  }
}