@media (min-width: 950px) {
    .team-layout{
        background-color: #EFF0F2;
        width: 93%;
        padding: 2%;
        border-radius: 20px;
        min-height: 500px;
        height: fit-content;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
    }
    
    .team-header{
        display: flex;
        align-items:flex-start;
        justify-content: space-between;
        width: 100%;
    }
    
    .team-title{
        width: 40%;
        font-size: 290%;
    }
    
    .team-desc{
        width: 40%;
        font-size: 190%;
    }
    
    .team-cards-wrap {
        margin-top: 5%;
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        scroll-snap-type: x mandatory; 
        width: 100%; 
    }
    
    .team-card {
        width: 350px;
        min-width: 350px; /* Минимальная ширина карточки */
        aspect-ratio: 380/463;
        background-color: #313A44;
        border-radius: 15px;
        position: relative;
        margin-right: 20px;
        scroll-snap-align: start; /* Выравнивание карточки по началу */
        flex-shrink: 0; /* Запрещаем сжатие карточки */
    } 
    
    .team-card-text-wrap{
        width: 90%;
        aspect-ratio: 370/34;
        padding: 4%;
        background-color: #FFFFFFCC;
        border-radius: 14px;
        position: absolute;
        bottom: 1%;
        left: 1%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .team-card-role{
        padding: 2% 4%;
        background-color: black;
        border-radius: 100px;
        font-size: 90%;
        color: white;
    }
    
    .team-card-name{
        font-size: 110%;
        font-weight: 500;
    }
    
    .team-controls {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      
      .team-controls button {
        padding: 10px 20px;
        margin: 0 10px;
        border: none;
        border-radius: 5px;
        background-color: #4CAF50;
        color: white;
        cursor: pointer;
      }
}

@media (max-width: 950px) {
    .team-layout{
        background-color: #EFF0F2;
        width: 93%;
        padding: 2%;
        border-radius: 20px;
        min-height: 500px;
        height: fit-content;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
    }
    
    .team-header{
        display: flex;
        align-items:flex-start;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
    }
    
    .team-title{
        width: 90%;
        font-size: 250%;
    }
    
    .team-desc{
        margin-top: 5%;
        width: 90%;
        font-size: 110%;
    }
    
    .team-cards-wrap {
        margin-top: 5%;
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        scroll-snap-type: x mandatory; 
        width: 100%; 
    }
    
    .team-card {
        width: 350px;
        min-width: 350px; /* Минимальная ширина карточки */
        aspect-ratio: 380/463;
        background-color: #313A44;
        border-radius: 15px;
        position: relative;
        margin-right: 20px;
        scroll-snap-align: start; /* Выравнивание карточки по началу */
        flex-shrink: 0; /* Запрещаем сжатие карточки */
    } 
    
    .team-card-text-wrap{
        width: 90%;
        aspect-ratio: 370/34;
        padding: 4%;
        background-color: #FFFFFFCC;
        border-radius: 14px;
        position: absolute;
        bottom: 1%;
        left: 1%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .team-card-role{
        padding: 2% 4%;
        background-color: black;
        border-radius: 100px;
        font-size: 90%;
        color: white;
    }
    
    .team-card-name{
        font-size: 110%;
        font-weight: 500;
    }
    
    .team-controls {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      
      .team-controls button {
        padding: 10px 20px;
        margin: 0 10px;
        border: none;
        border-radius: 5px;
        background-color: #4CAF50;
        color: white;
        cursor: pointer;
      }
}