.main-calc-lay-wrap{
    max-width: 1000px;
    min-width: 400px;
    width: calc(100% - 30px);
    padding: 20px;
    background-color: white;
    margin-top: 70px;
    border-radius: 20px;
}

.main-calc-layout{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.main-calc-info{
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: left;
}

.main-calc-info-title{
    font-size: 220%;
    font-weight: 500;
    line-height: 120%;
}

.main-calc-info-desc{
    font-size: 120%;
    margin-top: 3%;
}


.main-calc-machine-way-wrap{
    width: calc(100% - 40px);
    aspect-ratio: 345/70;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    border: 1px solid #E4E6E9;
}
.main-calc-machine-way-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
        margin-left: 7px;
        width: 80%;
        border: none;
        color: black;
        outline: none;
        background-color: #FFFFFF;
    }
    hr{
        margin-left: 22px;
        border: 0;
        height: 1px; 
        background: #E4E6E9;
        background-image: -webkit-linear-gradient(left, #fff, #000, #fff);
        background-image: -moz-linear-gradient(left, #fff, #000, #fff);
        background-image: -ms-linear-gradient(left, #fff, #000, #fff);
        background-image: -o-linear-gradient(left, #fff, #000, #fff);  

            
        width: 100%;
    }
}
.main-calc-change-btn{
    box-sizing: border-box;
    position: absolute;
    width: 10%;
    height: 1;
    aspect-ratio: 1;
    FONT-WEIGHT: 500;
    top: 35%;
    left: 75%;
    background: #FFFFFF;
    border: 1px solid #C9CED2;
    border-radius: 100px;
}

.main-calc-title-type-1{
    font-size: 150%;
    font-weight: 500;
    display: flex;
    text-align: left;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.main-calc-title-type-2{
    font-size: 110%;
    font-weight: 500;
    display: flex;
    text-align: left;
    color: #626C75;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.main-calc-deliviry-type{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: fit-content;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.main-calc-deliviry-type-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 11px 11px 8px;
    gap: 5px;
    width: 15%;
    height: 15px;
    background: #ffffff;
    border-radius: 50px;
    color: #626C75;
    border: 1px solid #626C75;
    flex: none;
    order: 0;
    flex-grow: 0;
    img{
        width: 40%;
    }
    font-size: 80%;
}


.main-calc-deliviry-type-item-act{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 11px 11px 8px;
    gap: 5px;
    width: 18%;
    height: 15px;
    background: #000000;
    border-radius: 50px;
    color: #ffffff;
    border: 1px solid #1F2225;
    flex: none;
    order: 0;
    flex-grow: 0;
    img{
        width: 25px;
        height: 25px;
    }
    font-size: 80%;
}


.main-calc-results{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 15px;
    width: 100%;
    height: fit-content;
    flex: none;
    margin-top: 10%;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.main-calc-result-text-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 83px;
    width: 100%;
    aspect-ratio: 313/59;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    align-items: baseline;
}

.main-calc-deliviry-result{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin: 0 auto;
    width: 40%;
    height: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.main-calc-deliviry-result-title{
           /* width: 94px; */
    /* height: 11px; */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 100%;
    line-height: 120%;
    color: #626C75;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.main-calc-deliviry-result-value{
    /* Frame 427320424 */

    width: 94px;
    height: 31px;
    display: flex;
    flex-direction: row;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    justify-content: space-between;
    text-wrap: nowrap;
    div{
        width: 100%;
        height: 31px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 150%;
        line-height: 120%;
        color: #1F2225;
    }
    span{
        /* Button */


        width: 20px;
        height: 15px;

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        margin-top: 12px;
        color: #626C75;


    }
}

.main-calc-next-action-btn{
    /* Frame 201 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 7px;
    color: white;
    width: calc(100% - 40px);

    /* красный-1 */
    background: #C3372E;
    border-radius: 42px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}





.radio-button {
    cursor: pointer;
    border: 1px solid #E4E6E9;
    border-radius: 100px;
    padding: 5px 10px 5px 5px;
    width: fit-content;
  }
  
  .radio-button input[type="radio"] {
    display: none;
  }
  
  .radio-button label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    cursor: pointer;
  }
  
  .radio-button .marker {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    position: relative; /* Добавляем position: relative; */
    margin-right: 5px;
  }
  
  .radio-button input[type="radio"]:checked + label .marker {
    background-color: #333;
  }
  
  .radio-button input[type="radio"]:checked + label .checkmark {
    display: flex;
    margin-top: 0px;
    margin-left: 0%;
    width: 4px;
    height: 8px;
  }
  
  .radio-button .checkmark {
    display: none;
    position: absolute;
    top: 30%;
    left: 38%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

.main-calc-select-row{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.main-calc-select-column{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.main-calc-cargo-paraps-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5%;
}