@media (min-width: 800px) {
    .container {
        display: flex;
        justify-content: space-between;
        width: 93vw;
        height: fit-content;
        margin-top: 5vh;
        background: #EFF0F2;
        border-radius: 15px;
        padding: 2%;
    }
    
    .faq {
        width: 50%;
        /* background: white; */
        padding: 20px;
        border-radius: 5px;
        /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    }
    
    .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
    
    .question-text {
        font-weight: 500;
        font-size: 120%;
    }
    
    .toggle {
        cursor: pointer;
        /* background-color: #007bff; */
        border: 1px solid black;
        color:black;
        font-size: 150%;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .answer {
        display: none;
        padding: 10px 0;
    }
    
    hr {
        margin: 10px 0;
    }
    
    .feedback {
        width: 25%;
        background: white;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        height: 60%;
        flex-direction: column;
        align-items: center;
        
    }
    
    .form {
        display: flex;
        flex-direction: column;
    }
    
    .faq h2{
        font-size: 190%;
        
    }
    .feedback label {
        margin-top: 10px;
    }
    .feedback span{
        font-size: 70%;
        text-align: center;
        margin-bottom: 5%;
    
    }
    .feedback form{
        width: 90%;
    }
    .feedback h2,
    .feedback span{
        width: fit-content;
    }
    .feedback input,
    .feedback textarea {
        padding: 5px;
        margin-top: 5px;
        border: none;
    }
    
    .feedback button {
        margin-top: 10px;
        padding: 15px;
        background-color: #C3372E;
        color: white;
        border: none;
        display: flex;
        border-radius: 100px;
        justify-content: space-between;
        width: 100%;
    }   
}


@media (max-width: 800px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 93vw;
        height: fit-content;
        margin-top: 5vh;
        background: #EFF0F2;
        border-radius: 15px;
        padding: 2%;
    }
    
    .faq {
        width: 95%;
        /* background: white; */
        padding: 20px;
        border-radius: 5px;
        /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    }
    
    .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
    
    .question-text {
        font-weight: 500;
        font-size: 120%;
    }
    
    .toggle {
        cursor: pointer;
        /* background-color: #007bff; */
        border: 1px solid black;
        color:black;
        font-size: 150%;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .answer {
        display: none;
        padding: 10px 0;
    }
    
    hr {
        margin: 10px 0;
    }
    
    .feedback {
        width: 95%;
        background: white;
        padding: 5px;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        height: 60%;
        flex-direction: column;
        align-items: center;
        
    }
    
    .form {
        display: flex;
        flex-direction: column;
    }
    
    .faq h2{
        font-size: 190%;
        
    }
    .feedback label {
        margin-top: 10px;
    }
    .feedback span{
        font-size: 70%;
        text-align: center;
        margin-bottom: 5%;
    
    }
    .feedback form{
        width: 90%;
    }
    .feedback h2,
    .feedback span{
        width: fit-content;
    }
    .feedback input,
    .feedback textarea {
        padding: 5px;
        margin-top: 5px;
        border: none;
    }
    
    .feedback button {
        margin-top: 10px;
        padding: 15px;
        background-color: #C3372E;
        color: white;
        border: none;
        display: flex;
        border-radius: 100px;
        justify-content: space-between;
        width: 100%;
    }   
}
