@media (min-width: 1100px) {
    .about-us-wrap{
        margin-top: 5vh;
        width: 98vw;
        height: 95vh;
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: center left;
        position: relative;
    }
    
    .about-us-title-1{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 230%;
        margin-top: 5%;
        color: #1F2225;
        font-weight: 500;
        line-height: 97%;
    }
    .about-us-title-2{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 230%;
        color: #626C75;
        font-weight: 500;
        margin-top: 0;
        line-height: 97%;
    }
    
    .about-us-desc{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 90%;
        margin-top: 2%;
        color: #1F2225;
        font-weight: 500;
        
    }
    
    .about-us-params-1,
    .about-us-params-2 {
        display: flex;
        flex-direction: column;
        width: fit-content; /* Содержимое будет занимать минимально необходимую ширину */
        font-size: 90%;
        color: #1F2225;
        font-weight: 500;
    }
    
    .about-us-params-1 p,
    .about-us-params-2 p {
        font-size: 300%;
        margin-bottom: 0;
    }
    
    .about-us-container {
        display: flex;
        justify-content: space-between;
        width: 42%;
        margin-top: 3%;
        margin-left: 55%;
    }
    
    .about-us-share-btn{
        display: flex;
        margin-left: 55%;
        width: fit-content;
        padding: 1%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        margin-top: 2%;
        img{
            padding-left: 5px;
        }
    }
    
    
    .about-us-flx-container{
        display: grid;
        grid-template-columns: 33% 33% 33%;
        width: 95%;
        margin-top: 5vh;
        margin-bottom: 5vh;
      }
      
      .about-us-flx-item{
        padding: 1.3% 2%;
        background-color: #EFF0F2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: auto;
        width: 90%;
        margin-top: 2vh;
        aspect-ratio: 455/250;
        img{
          margin-bottom: 20px;
          width: 10%;
        }
      }
      
      .about-us-flx-item-title{
        font-weight: 500;
        font-size: 110%;
      }
      
      .about-us-flx-item-desc{
        font-size: 90%;
        margin-top: 5%;
        color: #4B535B
      }
}

@media (max-width: 1100px) {
    .about-us-wrap{
        margin-top: 5vh;
        width: 98vw;
        height: 95vh;
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: center left;
        position: relative;
    }
    
    .about-us-title-1{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 230%;
        margin-top: 5%;
        color: #1F2225;
        font-weight: 500;
        line-height: 97%;
    }
    .about-us-title-2{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 230%;
        color: #626C75;
        font-weight: 500;
        margin-top: 0;
        line-height: 97%;
    }
    
    .about-us-desc{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 55%;
        font-size: 90%;
        margin-top: 2%;
        color: #1F2225;
        font-weight: 500;
        
    }
    
    .about-us-params-1,
    .about-us-params-2 {
        display: flex;
        flex-direction: column;
        width: fit-content; /* Содержимое будет занимать минимально необходимую ширину */
        font-size: 90%;
        color: #1F2225;
        font-weight: 500;
    }
    
    .about-us-params-1 p,
    .about-us-params-2 p {
        font-size: 300%;
        margin-bottom: 0;
    }
    
    .about-us-container {
        display: flex;
        justify-content: space-between;
        width: 42%;
        margin-top: 3%;
        margin-left: 55%;
    }
    
    .about-us-share-btn{
        display: flex;
        margin-left: 55%;
        width: fit-content;
        padding: 1%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        margin-top: 2%;
        img{
            padding-left: 5px;
        }
    }
    
    
    .about-us-flx-container{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 95%;
        margin-top: 5vh;
        margin-bottom: 5vh;
      }
      
      .about-us-flx-item{
        padding: 1.3% 2%;
        background-color: #EFF0F2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: auto;
        width: 90%;
        margin-top: 2vh;
        aspect-ratio: 455/250;
        img{
          margin-bottom: 20px;
          width: 10%;
        }
      }
      
      .about-us-flx-item-title{
        font-weight: 500;
        font-size: 110%;
      }
      
      .about-us-flx-item-desc{
        font-size: 90%;
        margin-top: 5%;
        color: #4B535B
      }
}

@media (max-width: 760px) {
    .about-us-wrap{
        margin-top: 5vh;
        width: 98vw;
        height: 115vh;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;
    }
    
    #break-point-1{
        margin-top: 50%;
    }
    .about-us-title-1{
        display: flex;
        flex-direction: column;
        width: 95%;
        font-size: 230%;
        
        margin: auto;
        margin-top: 105%;
        color: #1F2225;
        font-weight: 500;
        line-height: 97%;
    }
    .about-us-title-2{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 55%;
        width: 95%;
        margin: auto;
        font-size: 230%;
        color: #626C75;
        font-weight: 500;
        margin-top: 0;
        line-height: 97%;
    }
    
    .about-us-desc{
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: auto;
        font-size: 90%;
        margin-top: 2%;
        color: #1F2225;
        font-weight: 500;
        
    }
    
    .about-us-params-1,
    .about-us-params-2 {
        display: flex;
        flex-direction: column;
        width: fit-content; /* Содержимое будет занимать минимально необходимую ширину */
        font-size: 90%;
        color: #1F2225;
        font-weight: 500;
    }
    
    .about-us-params-1 p,
    .about-us-params-2 p {
        font-size: 300%;
        margin-bottom: 0;
    }
    
    .about-us-container {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: auto;
    }
    
    .about-us-share-btn{
        display: flex;
        margin-left: 55%;
        width: fit-content;
        padding: 2% 5%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        margin-left: 5%;
        margin-top: 5%;
        img{
            padding-left: 5px;
        }
    }
    
    
    .about-us-flx-container{
        display: grid;
        grid-template-columns: 100%;
        width: 95%;
        margin-top: 5vh;
        margin-bottom: 5vh;
      }
      
      .about-us-flx-item{
        padding: 1.3% 2%;
        background-color: #EFF0F2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: auto;
        width: 90%;
        margin-top: 2vh;
        aspect-ratio: 455/250;
        img{
          margin-bottom: 20px;
          width: 10%;
        }
      }
      
      .about-us-flx-item-title{
        font-weight: 500;
        font-size: 110%;
      }
      
      .about-us-flx-item-desc{
        font-size: 90%;
        margin-top: 5%;
        color: #4B535B
      }
}