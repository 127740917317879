@media (min-width: 1080px) {
    .footer-wrap{
        width: 98%;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 3%;
        margin-bottom: 3%;
        flex-wrap: wrap;
    }
    
    .footer-gift-wrap{
        background-color: #C3372E;
        width: 25%;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-position: center bottom;
        background-repeat: no-repeat;
        min-width: 286px;
        min-height: 367px;
        height: 100%;
        aspect-ratio: 367/286;
        position: relative;
    }
    
    .footer-content-devider{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        height: 100%;
        
    }
    
    .footer-info-content-wrap-1{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .footer-info-content-wrap-2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #4B535B;
        font-size: 80%;
    }
    
    .footer-gift-title{
        color: white;
        font-size: 170%; 
    }
    
    .footer-gift-btn{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        padding: 1% 4%;
        margin-top: 55%;
        position: absolute;
        bottom: 4%;
        border-radius: 100px;
        text-wrap: nowrap;
    }
    
    .footer-gift-description{
        color: white;
        font-size: 90%; 
        display: flex;
        margin-top: 2%;
    }
    
    .footer-contacts-wrap{
        display: flex;
        flex-direction: column;
        *{
            margin-bottom: 7%;
        }
    }
    
    .footer-parts-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        *{
            margin-bottom: 5%;
            width: fit-content;
        }
    }
    
    .footer-social-links-wrap{
        display: flex;
        flex-direction: column;
        background-color: #EFF0F2;
        border-radius: 12px;
        padding: 2%;
        height: fit-content;
        justify-content: space-between;
        font-size: 90%;
    }
}




@media ((min-width: 780px) and (max-width: 1080px)) {
    .footer-wrap{
        width: 98%;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    
    .footer-gift-wrap{
        background-color: #C3372E;
        width: 25%;
        border-radius: 10px;
        padding: 1%;
        display: flex;
        flex-direction: column;
        background-position: center bottom;
        background-repeat: no-repeat;
        min-width: 286px;
        min-height: 367px;
        aspect-ratio: 367/286;
        position: relative;
    }
    
    .footer-content-devider{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 70%;
        margin-left: 30px;
    }
    
    .footer-info-content-wrap-1{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .footer-info-content-wrap-2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #4B535B;
        font-size: 60%;
    }
    
    .footer-gift-title{
        color: white;
        font-size: 170%; 
    }
    
    .footer-gift-btn{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        padding: 1% 4%;
        margin-top: 65%;
        position: absolute;
        bottom: 4%;
        border-radius: 100px;
        text-wrap: nowrap;
    }
    
    .footer-gift-description{
        color: white;
        font-size: 90%; 
        display: flex;
        margin-top: 2%;
    }
    
    .footer-contacts-wrap{
        display: flex;
        flex-direction: column;
        *{
            margin-bottom: 7%;
            font-size: 80%;
        }
    }
    
    .footer-parts-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        *{
            margin-bottom: 5%;
            width: fit-content;
            font-size: 80%;
        }
    }
    
    .footer-social-links-wrap{
        display: none;
        flex-direction: column;
        background-color: #EFF0F2;
        border-radius: 12px;
        padding: 2%;
        height: fit-content;
        justify-content: space-between;
        font-size: 70%;
    }
}




@media ((min-width: 280px) and (max-width: 780px)) {
    .footer-wrap{
        width: 98%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 15%;
        margin-bottom: 3%;

    }
    
    .footer-gift-wrap{
        background-color: #C3372E;
        width: 25%;
        border-radius: 10px;
        padding: 5%;
        display: flex;
        flex-direction: column;
        background-position: center bottom;
        background-repeat: no-repeat;
        min-width: 286px;
        min-height: 367px;
        aspect-ratio: 367/286;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
    
    .footer-content-devider{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        margin-top: 30px;
    }
    
    .footer-info-content-wrap-1{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .footer-info-content-wrap-2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #4B535B;
        font-size: 60%;
    }
    
    .footer-gift-title{
        color: white;
        font-size: 170%; 
    }
    
    .footer-gift-btn{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        padding: 1% 4%;
        margin-top: 55%;
        position: absolute;
        bottom: 4%;
        border-radius: 100px;
        text-wrap: nowrap;
    }
    
    .footer-gift-description{
        color: white;
        font-size: 90%; 
        display: flex;
        margin-top: 2%;
    }
    
    .footer-contacts-wrap{
        display: flex;
        flex-direction: column;
        *{
            margin-bottom: 7%;
            font-size: 80%;
        }
    }
    
    .footer-parts-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        *{
            margin-bottom: 5%;
            width: fit-content;
            font-size: 80%;
        }
    }
    
    .footer-social-links-wrap{
        display: none;
        flex-direction: column;
        background-color: #EFF0F2;
        border-radius: 12px;
        padding: 2%;
        height: fit-content;
        justify-content: space-between;
        font-size: 70%;
    }
}