.navbar {
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    height: 50px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
}

.nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none; /* Убирает маркеры списка */
    margin: 0; /* Убирает отступы */
    padding: 0; /* Убирает внутренние отступы */
}

.nav-list li {
    padding: 0 10px; /* Добавляем горизонтальные отступы для элементов списка */
}

.sub-list {
    list-style-type: none; /* Убирает маркеры у подсписка */
    margin: 0; /* Убирает отступы */
    padding: 0; /* Убирает внутренние отступы */
}

#recall{
    padding: 2% 3%;
    border-radius: 100px;
    background-color: red;
}

.nav-contacts{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        margin: 15px;
    }
}

@media (max-width: 959px) {
    .navbar {
        display: flex;
        flex-direction: row;
        width: calc(100% - 30px);
        font-size: 80%;
        padding: 3px 10px;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        position: absolute;
    }

    .navbar img{
        width: 25%;
    }
    
    .nav-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none; /* Убирает маркеры списка */
        margin: 0; /* Убирает отступы */
        padding: 0; /* Убирает внутренние отступы */
    }
    
    .nav-list li {
        padding: 0 10px; /* Добавляем горизонтальные отступы для элементов списка */
    }
    
    .sub-list {
        list-style-type: none; /* Убирает маркеры у подсписка */
        margin: 0; /* Убирает отступы */
        padding: 0; /* Убирает внутренние отступы */
    }
    
    #recall{
        padding: 2% 3%;
        border-radius: 100px;
        background-color: red;
    }
    
    .nav-contacts{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            margin: 15px;
        }
    }
}

@media (max-width: 800px) {
    .navbar {
        display: flex;
        flex-direction: row;
        width: calc(100% - 30px);
        font-size: 80%;
        padding: 3px 10px;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        display: none;
    }

    .navbar img{
        width: 25%;
    }
    
    .nav-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none; /* Убирает маркеры списка */
        margin: 0; /* Убирает отступы */
        padding: 0; /* Убирает внутренние отступы */
    }
    
    .nav-list li {
        padding: 0 10px; /* Добавляем горизонтальные отступы для элементов списка */
    }
    
    .sub-list {
        list-style-type: none; /* Убирает маркеры у подсписка */
        margin: 0; /* Убирает отступы */
        padding: 0; /* Убирает внутренние отступы */
    }
    
    #recall{
        padding: 2% 3%;
        border-radius: 100px;
        background-color: red;
    }
    
    .nav-contacts{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            margin: 15px;
        }
    }
}