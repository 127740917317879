@media (min-width: 1250px) {
    .transportation-layout{
        margin-top: 5%;
        width: 97%;
    }
    
    .transportation-grid{
        width: 100%;
        margin-top: 2%;
        display: grid;
        grid-template-columns: 91% 9%;
    }
    
    .trans-main-block{
        width: 98%;
        padding: 1%;
        aspect-ratio: 1279/575;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: grid;
        grid-template-columns: 50% 50%;
        img{
            width: 100%;
            margin: auto;
            border-radius: 15px;
        }
    }
    
    .trans-count-block{
        width: 71%;
        padding: 10%;
        margin-left: 17%;
        height: 96%;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-info{
        width: 94%;
        padding: 3%;
        height: 96%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .transportation-cart-header{
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }
    
    .transportation-cart-text{
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .transportation-cart-desc{
        margin-top: 2%;
        font-size: 110%;
        width: 60%;
    }
    
    .transportation-deliver-time-wrap{
        width: 30%;
        display: flex;
        height: fit-content;
        flex-direction: column;
        background-color: white;
        border-radius: 12px;
        min-width: 160px;
        padding: 2%;
    }
    
    .transportation-cart-btns{
        margin-top: 8%;
        display: flex;
        flex-direction: row;
    }
    
    .transportation-cart-btn {
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
    
        img {
            width: 10%;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    
    
    .transportation-share-cart-btn{
        margin-left: 3%;
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
        img{
            width: 10%;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    .transportation-deliver-time-wrap-up{
        display: flex;
        width: 100%;
        img{
            width: 15%;
            margin-left: 0;
            margin-right: 2%;
        }
        span{
            margin-left: 3%;
            color: #4B535B;
        }
    }
    
    .transportation-deliver-time-wrap-down{
        margin-top: 5%;
        display: flex;
        width: 100%;
        align-items: flex-end;
        b{
            font-size: 180%;
            font-weight: 500;
        }
        span{
            color: #4B535B;
            margin-left: 5%;
            margin-bottom: 2%;
        }
    }
    
    #transportation-road{
        display: flex;
        width: 95%;
        position: absolute;
        bottom: 2%;
    }

    #transportation-road-m{
        display: none;
      }
    .trans-count-block {
        /* Add position: relative if it's not already set */
        position: relative; 
      }
      
      .trans-counter-wrap {
        width: 60%;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        align-items: flex-end; /* Keeps the counter at the bottom */
        position: relative; /* Needed for absolute positioning of buttons */
        b {
          font-size: 400%;
          font-weight: 500;
        }
        span {
          font-size: 120%;
          margin-bottom: 15%;
        }
      }
      
      .trans-counter-decriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 17%; /* Place them at the bottom */
        left: 50%; /* Start from the center */
        transform: translateX(-50%);
      }
    
      .trans-counter-incriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 5%; /* Place them at the bottom */
        left: 50%; /* Start from the center */
        transform: translateX(-50%);
      }
      
      .trans-counter-decriment + .trans-counter-decriment { /* Style for the second button */
        margin-top: 10px; /* Add spacing between buttons */
      } 
}

@media ((min-width: 920px) and (max-width: 1250px)) {
    .transportation-layout{
        margin-top: 5%;
        width: 97%;
    }
    
    .transportation-grid{
        width: 100%;
        margin-top: 2%;
        display: grid;
        grid-template-columns: 91% 9%;
    }
    
    .trans-main-block{
        width: 98%;
        padding: 1%;
        aspect-ratio: 890/541;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: grid;
        grid-template-columns: 50% 50%;
        position: relative;
        img{
            width: 100%;
            margin: auto;
            margin-top: 5%;
            border-radius: 15px;
        }
    }
    
    .trans-count-block{
        width: 71%;
        padding: 10%;
        margin-left: 17%;
        height: 96%;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-info{
        width: 94%;
        padding: 3%;
        height: 96%;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-header{
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-text{
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .transportation-cart-desc{
        margin-top: 2%;
        font-size: 110%;
        width: 90%;
    }
    
    .transportation-deliver-time-wrap{
        width: 30%;
        display: flex;
        height: fit-content;
        flex-direction: column;
        background-color: white;
        border-radius: 12px;
        min-width: 160px;
        padding: 2%;
        margin-top: 5%;
    }
    
    .transportation-cart-btns{
        margin-top: 8%;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-btn {
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
        font-size: 90%;
        img {
            width: 10%;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    
    
    .transportation-share-cart-btn{
        margin-top: 3%;
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
        font-size: 90%;
        img{
            width: 10%;
            margin: auto;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    .transportation-deliver-time-wrap-up{
        display: flex;
        width: 100%;
        img{
            width: 15%;
            margin-left: 0;
            margin-right: 2%;
        }
        span{
            margin-left: 3%;
            color: #4B535B;
        }
    }
    
    .transportation-deliver-time-wrap-down{
        margin-top: 5%;
        display: flex;
        width: 100%;
        align-items: flex-end;
        b{
            font-size: 180%;
            font-weight: 500;
        }
        span{
            color: #4B535B;
            margin-left: 5%;
            margin-bottom: 2%;
        }
    }
    
    
    .trans-count-block {
        /* Add position: relative if it's not already set */
        position: relative; 
      }
      
      .trans-counter-wrap {
        width: 60%;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        align-items: flex-end; /* Keeps the counter at the bottom */
        position: relative; /* Needed for absolute positioning of buttons */
        b {
          font-size: 400%;
          font-weight: 500;
        }
        span {
          font-size: 120%;
          margin-bottom: 15%;
        }
      }
      
      .trans-counter-decriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 17%; /* Place them at the bottom */
        left: 50%; /* Start from the center */
        transform: translateX(-50%);
      }
    
      .trans-counter-incriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 5%; /* Place them at the bottom */
        left: 50%; /* Start from the center */
        transform: translateX(-50%);
      }
      
      .trans-counter-decriment + .trans-counter-decriment { /* Style for the second button */
        margin-top: 10px; /* Add spacing between buttons */
      } 
      #transportation-road{
        display: none;
      #transportation-road-m{
        position: absolute;
        bottom: 0;
      }
    }
}

@media ((max-width: 920px) and (min-width: 120px)) {
    .transportation-layout{
        margin-top: 5%;
        width: 97%;
    }
    
    .transportation-grid{
        width: 100%;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
    }
    
    .trans-main-block{
        width: 98%;
        padding: 1%;
        height: fit-content;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: flex;
        /* grid-template-columns: 50% 50%; */
        position: relative;
        flex-direction: column;
        img{
            width: 100%;
            margin: auto;
            margin-top: 5%;
            border-radius: 15px;
        }
    }
    
    .trans-count-block{
        width: 71%;
        padding: 10%;
        margin-left: 17%;
        height: 96%;
        background-color: #EFF0F2;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-info{
        width: 94%;
        padding: 3%;
        height: 96%;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-header{
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-text{
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .transportation-cart-desc{
        margin-top: 2%;
        font-size: 110%;
        width: 90%;
    }
    
    .transportation-deliver-time-wrap{
        width: 30%;
        display: flex;
        height: fit-content;
        flex-direction: column;
        background-color: white;
        border-radius: 12px;
        min-width: 160px;
        padding: 2%;
        margin-top: 5%;
    }
    
    .transportation-cart-btns{
        margin-top: 8%;
        display: flex;
        flex-direction: column;
    }
    
    .transportation-cart-btn {
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        background-color: #C3372E;
        color: white;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
        font-size: 90%;
        img {
            width: 10%;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    
    
    .transportation-share-cart-btn{
        margin-top: 3%;
        display: flex;
        width: fit-content;
        padding: 2% 3%;
        color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 100px;
        text-wrap: nowrap;
        justify-content: space-between; 
        font-size: 90%;
        img{
            width: 10%;
            margin: auto;
            margin-left: 3%; /* Add margin to the right of the image */
        }
    }
    .transportation-deliver-time-wrap-up{
        display: flex;
        width: 100%;
        img{
            width: 15%;
            margin-left: 0;
            margin-right: 2%;
        }
        span{
            margin-left: 3%;
            color: #4B535B;
        }
    }
    
    .transportation-deliver-time-wrap-down{
        margin-top: 5%;
        display: flex;
        width: 100%;
        align-items: flex-end;
        b{
            font-size: 180%;
            font-weight: 500;
        }
        span{
            color: #4B535B;
            margin-left: 5%;
            margin-bottom: 2%;
        }
    }
    
    
    .trans-count-block {
        margin-left: 0;
        width: 80%;
        margin-top: 5%;
        padding: 2% 10%;
        height: fit-content;
        position: relative; 
      }
      
      .trans-counter-wrap {
        width: 12%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end; /* Keeps the counter at the bottom */
        position: relative; /* Needed for absolute positioning of buttons */
        b {
          font-size: 300%;
          font-weight: 500;
        }
        span {
          font-size: 120%;
          margin-bottom: 5%;
        }
      }
      
      .trans-counter-decriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 50%; /* Place them at the bottom */
        right: 20%; /* Start from the center */
        transform: translateY(50%);
      }
    
      .trans-counter-incriment { 
        position: absolute; /* Position buttons absolutely */
        bottom: 50%; /* Place them at the bottom */
        right: 10%; /* Start from the center */
        transform: translateY(50%);
      }
      
      .trans-counter-decriment + .trans-counter-decriment { /* Style for the second button */
        margin-top: 10px; /* Add spacing between buttons */
      } 
      #transportation-road{
        display: none;
      #transportation-road-m{
        position: absolute;
        bottom: 0;
      }
    }
}