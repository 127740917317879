@media (min-width: 1400px) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        /* background-color: #353A40; */
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 320%;
        margin-bottom: 2%;
        width: 50%;
    }
    
    .why-we-block-desc{
        font-size: 150%;
        margin-bottom: 2%;
        line-height: 160%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}

@media (min-width: 1250px) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 300%;
        margin-bottom: 2%;
        width: 50%;
    }
    
    .why-we-block-desc{
        font-size: 130%;
        margin-bottom: 2%;
        line-height: 140%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}


@media (min-width: 1100px) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 280%;
        margin-bottom: 2%;
        width: 70%;
    }
    
    .why-we-block-desc{
        font-size: 110%;
        margin-bottom: 2%;
        line-height: 160%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}

@media (min-width: 1250px ) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 300%;
        margin-bottom: 2%;
        width: 50%;
    }
    
    .why-we-block-desc{
        font-size: 130%;
        margin-bottom: 2%;
        line-height: 140%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}


@media ((min-width: 950px) and (max-width: 1250px)) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 210%;
        margin-bottom: 2%;
        width: 70%;
    }
    
    .why-we-block-desc{
        font-size: 90%;
        margin-bottom: 2%;
        line-height: 130%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}

@media ((min-width: 850px)  and (max-width: 950px)) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 200%;
        margin-bottom: 2%;
        width: 70%;
    }
    
    .why-we-block-desc{
        font-size: 90%;
        margin-bottom: 2%;
        line-height: 130%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 140%;
    }
}

@media ((min-width: 650px) and (max-width: 850px)) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 180%;
        margin-bottom: 2%;
        width: 70%;
    }
    
    .why-we-block-desc{
        font-size: 90%;
        margin-bottom: 2%;
        line-height: 100%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 110%;
    }
}

@media ((min-width: 450px) and (max-width: 650px)) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 98%;
        height: 70vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 94%;
        aspect-ratio: 1400 / 504;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 2%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: 115% 600%;
        background-repeat: no-repeat;
        background-size: 40%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 120%;
        margin-bottom: 2%;
        width: 90%;
    }
    
    .why-we-block-desc{
        font-size: 80%;
        margin-bottom: 2%;
        line-height: 100%;
        width: 60%;
    }
    
    .why-we-block-btn-wrap{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 0.3% 1%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        position: absolute;
        bottom: 5%;
        font-size: 110%;
    }
}

@media ((min-width: 150px) and (max-width: 450px)) {
    .why-we-title{
        font-size: 4vh;
        font-weight: 500;
        width: 97%;
        text-align: center;
        margin-top: 5%;
    }
    
    iframe{
        width: 97%;
        height: 80vh;
        background-color: #353A40;
        border-radius: 15px;
        margin-top: 3%;
    }
    
    .why-we-block-wrap{
        width: 87%;
        aspect-ratio: 355/766;
        background-color: #C3372E;
        border-radius: 15px;
        color: white;
        padding: 5%;
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 70%;
        position: relative;
    }
    
    .why-we-block-title{
        font-size: 190%;
        margin-bottom: 5%;
        width: 90%;
    }
    
    .why-we-block-desc{
        font-size: 120%;
        margin-bottom: 10%;
        width: 90%;
    }
    
    .why-we-block-btn-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        padding: 2% 5%;
        background: #FFFFFF;
        border-radius: 30px;
        width: fit-content;
        
        font-size: 130%;
    }
}