@media (min-width: 1300px) {
    .indastrias-wrap{
        height: fit-content;
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 280%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 140%;
        margin-left: 40%;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: 100%;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 1.5%;
        border-radius: 15px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 1.5%;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        
    }
    .ind-flx-item-title{
        font-size: 180%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
}

@media (max-width: 1299px) {
    .indastrias-wrap{
        /* min-height: 200vh; */
        height: fit-content;
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 240%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 110%;
        margin-left: 40%;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: 100%;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 1.5%;
        border-radius: 15px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 1.5%;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        font-size: 80%;
    }
    .ind-flx-item-title{
        font-size: 150%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
}


@media (max-width: 1000px) {
    .ind-logo{
        width: 10%;
    }
    .indastrias-wrap{
        /* min-height: 270vh; */
        height: fit-content;
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 110%;
        margin: auto;
        padding: 10px;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 100%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: 100%;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 1.5%;
        border-radius: 15px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 1.5%;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        min-height: 200px;
        margin-top: 1%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        font-size: 80%;
    }
    .ind-flx-item-title{
        font-size: 150%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
}

@media (max-width: 1000px) and (min-width: 690px) {
    .ind-logo{
        width: 10%;
    }
    .indastrias-wrap{
        /* min-height: 290vh; */
        height: fit-content;
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 110%;
        margin: auto;
        padding: 10px;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 100%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: 100%;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 1.5%;
        border-radius: 15px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 1.5%;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        min-height: 200px;
        margin-top: 1%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        font-size: 80%;
    }
    .ind-flx-item-title{
        font-size: 150%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .ind-logo{
        width: 10%;
    }
    .indastrias-wrap{
        /* height: 270vh; */
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 180%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 110%;
        margin: auto;
        padding: 10px;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 100%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: 100%;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 1.5%;
        border-radius: 15px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        margin-top: 1%;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 1.5%;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        grid-template-columns: 60% 40%;
        width: 95%;
        min-height: 200px;
        margin-top: 1%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        font-size: 80%;
    }
    .ind-flx-item-title{
        font-size: 150%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
}

@media (max-width: 700px) {
    .ind-logo{
        width: 10%;
    }
    .indastrias-wrap{
        /* height: 270vh; */
        height: fit-content;
        width: 93vw;
        border-radius: 20px;
        background-color: #EFF0F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .ind-title{
        font-size: 150%;
        font-weight: 500;
    }
    
    .ind-desc{
        font-size: 80%;
        margin: auto;
        padding: 10px;
        display: flex;
        width: fit-content;
    }
    
    .ind-flx-item-container{
        display: grid;
        grid-template-columns: 100%;
        margin-top: 5%;
        justify-content: space-between;
        width: 100%;
    
    }
    
    .ind-flx-item-text-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 5%;
        justify-content: space-between;
        height: fit-content;
    }
    
    .ind-flx-item{
        background-color: white;
        padding: 10px;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        width: calc(100% - 20px);
        margin-top: 1%;
        padding-bottom: 20px;
    }
    
    
    .gift-item{
        background-color: #353A40;
        padding: 20px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        grid-template-columns: 60% 40%;
        width: calc(100% - 40px);
        aspect-ratio: 315 / 400;
        margin-top: 5%;
        background-size: 110%;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
    }
    
    
    .ind-flx-item-desc{
        font-size: 90%;
    }
    .ind-flx-item-title{
        font-size: 170%;
        margin-top: 10%;
        margin-bottom: 2%;
    }
    
    .ind-flx-item-img{
        border-radius: 8px;
        display: flex;
        margin: auto;
    }
    
    .ind-flx-item-btn-wrap{
        padding: 2% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 5%;
        font-size: 120%;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }
    
    .ind-flx-item-gift-wrap{
        display: none;
        padding: 0.5% 5%;
        border-radius: 100px;
        border: 1px solid #000;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;
        text-wrap: nowrap;
        display: none;
        img{
            display: flex;
            margin-left: 5%;
            width: 100%;
        }
    }

    #gift-title{
        margin-top: 0;
        width: 90%;
    }

    #gift-desc{
        margin-top: 5%;
        width: 90%;
    }
}
